.home-page-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
}

.home-page-container {
  box-shadow: 0px 0.25rem 1rem rgba(206, 212, 240, 0.8);
  margin: 10% auto auto auto;
  width: 38rem;
  border-radius: 1rem;
  padding: 3rem 3rem 3rem 3rem;
  background-color: #ffffff;
}

.home-page-tabs-container {
  display: flex;
  margin-bottom: 3rem;
}

.home-page-tab {
  font-size: 1.25rem;
  color: var(--primary-text-color);
  font-weight: bold;
  margin-right: 3rem;
  text-underline-offset: 0.5rem;
  cursor: pointer;
}

.home-page-active-tab {
  text-decoration: underline;
  text-decoration-color: var(--secondary-text-color);
}

@media (max-width: 45rem) {
  .home-page-container {
    border-radius: 1rem;
    width: auto;
    margin: 20% 0.5rem 0 0.5rem;
    padding: 3rem 1rem;
  }
}
