.work-layout {
  display: flex;
  gap: 3.5em;
  margin: 1em 0;
}

.work-any-timing-layout {
  display: flex;
  gap: 3.5em;
  margin: 0;
}

.work-industry {
  margin-top: 0.5rem;
}

.work-industry-dropdown {
  max-width: 35rem;
}

.work-industry-checkbox-container {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
}

@media (max-width: 1023px) {
  .work-layout {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin: 3.5rem 0;
  }

  .work-any-timing-layout {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin: 0 0 1em 0;
  }
}

@media (max-width: 650px) {
  .work-industry {
    margin-top: 4.5rem;
  }
}
