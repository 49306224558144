.revview-order-container {
  position: relative;
}

.revview-change-order {
  position: absolute;
  top: 1rem;
  right: 0;
}

.review-container {
  position: relative;
  margin-top: 5rem;
  padding-bottom: 5rem;
}

.review-text-container {
  display: flex;
  justify-content: space-between;
}

.review-text {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.review-accordion-container {
  margin: 1rem 0;
}

.review-accordion-error-border {
  border: 2px solid #ffcccc;
  animation-name: blinking;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes blinking {
  50% {
    border-color: #ff0000;
  }
}

.review-save-container {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  align-items: center;
}

.review-save-button-container {
  display: flex;
  gap: 0.5rem;
}

.submission-container {
  display: flex;
  flex-direction: column;
  margin: 2rem auto;
}

.submission-info-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 1.5rem 0 0 0;
}

.submission-info-container-text {
  font-size: 1.125rem;
  font-weight: bold;
}

.submission-text {
  font-size: 1.125rem;
  margin-top: 8rem;
  text-align: center;
}

.submission-dashboard {
  margin-top: 1.25rem;
  width: fit-content;
  align-self: center;
}

@media (max-width: 35rem) {
  .review-save-container {
    display: flex;
    gap: 0;
    flex-direction: column;
  }

  .review-save {
    width: fit-content;
  }

  .review-text {
    font-size: 1.125rem;
  }

  .submission-text {
    font-size: 1rem;
    text-align: left;
  }
  .submission-info-container {
    margin: 1rem 0 0 0;
  }

  .submission-info-container-text {
    font-size: 1rem;
  }
}
