.order-container {
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
}

.order-text {
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  align-items: center;
}

.order-items-container {
  display: flex;
  justify-content: space-evenly;
  width: -webkit-fill-available;
  width: -moz-available;
  background: rgba(155, 169, 232, 0.4);
  padding: 0 2rem;
  margin-right: 1.5rem;
}

.order-items-container1 {
  padding: 2rem 0.75rem 1rem 0.75rem;
}

.order-items-container2 {
  padding-bottom: 2rem;
}

.order-items-wrapper {
  display: flex;
  justify-content: space-evenly;
  width: -webkit-fill-available;
  width: -moz-available;
}

.order-item:not(:last-child) {
  margin-right: 2rem;
}

.order-index {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  padding-top: 1rem;
}

.order-icon-text-container {
  position: relative;
  width: 3.25rem;
  height: 3.25rem;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid black;
  background: #d6d6d6;
}

.order-icon-text {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: absolute;
  top: 0.25rem;
  left: 0.5rem;
  font-size: 1.25rem !important;
}

.order-icon-container {
  position: relative;
  width: 6.25rem;
  height: 6.25rem;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid black;
  background: #d6d6d6;
}

.order-icon {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
}

.order-item-text {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.5rem;
  text-align: center;
}

.order-button-container {
  display: flex;
  gap: 0.5rem;
  align-self: center;
  margin-top: 2rem;
}

@media (max-width: 1023px) {
  .order-container {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
  }

  .order-items-container {
    padding: 0.5rem;
  }

  .order-icon-container {
    width: 5rem;
    height: 5rem;
  }

  .order-icon {
    width: 4rem;
    height: 4rem;
  }
}

@media (max-width: 899px) {
  .order-items-container {
    display: flex;
    flex-direction: column;
    width: auto;
    margin: 1.5rem auto 1.5rem auto;
    padding: 1.5rem 3rem 3rem 3rem;
  }

  .order-item {
    display: flex;
    flex-direction: column;
  }

  .order-icon-container {
    align-self: center;
  }

  .order-index {
    margin-top: 1.5rem;
  }
}
