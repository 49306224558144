.preferences-order-container {
  position: relative;
}

.preferences-change-order {
  position: absolute;
  top: 1rem;
  right: 0;
}

.preferences-container {
  padding-bottom: 1rem;
}

.preferences-tabs-container {
  display: flex;
  justify-content: space-around;
  background: rgba(155, 169, 232, 0.4);
  border-radius: 1rem;
  width: auto;
  margin-top: 5rem;
  padding: 0;
}

.preferences-tabs-tab {
  margin: 0.5rem 0;
  padding: 0 0.5rem;
}

.preferences-tabs-active-tab {
  background: #f1f7ff;
  border-radius: 0.75rem;
}

.preferences-tabs-text {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;
  padding: 0.625rem 0.625rem;
  cursor: pointer;
}

.preferences-tabs-active-text {
  cursor: default;
}

.preferences-tab-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.preferences-tab-image {
  width: 1.5rem;
  height: 1.5rem;
}

.preferences-tabs-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--secondary-text-color);
  margin-bottom: 1rem;
}

.preferences-form-container {
  padding: 1rem 0 1rem 0;
  margin: 0.5rem;
}

.preferences-form-navigation-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.preferences-form-navigation-icon {
  cursor: pointer;
}

.preferences-button-wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

@media (max-width: 951px) {
  .preferences-form-container {
    margin-top: 4rem;
  }
}
