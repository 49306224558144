.login-email-container {
  display: flex;
  height: 2.625rem;
  margin-bottom: 0.5rem;
}

.login-email {
  width: 22rem;
  margin-right: 1rem;
}

.login-send-otp-container {
  display: flex;
  gap: 0.5rem;
}

.login-send-otp {
  margin-left: 1rem;
}

.login-error {
  color: var(--error-message-color);
  font-size: 1rem;
  margin-top: 0.5rem;
}

.login-otp-input {
  border-radius: 0.5rem;
  width: 2.625rem !important;
  margin-right: 1rem;
  height: 2.625rem;
  border: 0.09375rem solid var(--text-input-default-border);
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--text-input-active-color);
}

.login-otp-input {
  &:disabled {
    border: 0.09375rem solid var(--text-input-disabled-border);
    color: var(--text-input-disabled-color);
  }
  &:focus {
    border: 0.09375rem solid var(--text-input-active-border);
    color: var(--text-input-actve-color);
    outline: none;
  }
}

.login-enter-otp {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.login-submit-container {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.login-login {
  margin-top: 1.5rem;
  font-size: 1rem;
}

.login-login-link {
  color: var(--secondary-text-color);
  cursor: pointer;
  font-weight: bold;
}

@media (max-width: 45rem) {
  .login-email-container {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .login-input {
    width: 90%;
  }

  .login-email {
    width: 90%;
  }

  .login-send-otp {
    margin-left: 0;
  }

  .login-verify-otp-container {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
  }
}

@media (max-width: 25rem) {
  .login-input {
    width: auto;
  }

  .login-email {
    width: auto;
    margin-right: 0;
  }
  .login-enter-otp {
    font-size: 0.875rem;
    line-height: 1rem;
  }

  .login-otp-input {
    font-size: 0.875rem;
    line-height: 1rem;
  }

  .login-error {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}
