.signup-text-field-container {
  padding-bottom: 0.5rem;
}

.signup-input {
  width: auto;
  height: 2.625rem;
}

.signup-email-container {
  display: flex;
  height: 2.625rem;
  margin-bottom: 0.5rem;
}

.signup-email {
  width: 25.5rem;
  margin-right: 1rem;
}

.signup-send-otp-container {
  display: flex;
  gap: 0.5rem;
}

.signup-send-otp {
  margin-left: 1rem;
}

.signup-error {
  color: var(--error-message-color);
  font-size: 1rem;
  margin-top: 0.5rem;
}

.signup-otp-input {
  border-radius: 0.5rem;
  width: 2.625rem !important;
  margin-right: 1rem;
  height: 2.625rem;
  border: 0.09375rem solid var(--text-input-default-border);
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--text-input-active-color);
}

.signup-otp-input {
  &:disabled {
    border: 0.09375rem solid var(--text-input-disabled-border);
    color: var(--text-input-disabled-color);
  }
  &:focus {
    border: 0.09375rem solid var(--text-input-active-border);
    color: var(--text-input-actve-color);
    outline: none;
  }
}

.signup-enter-otp {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.signup-submit-container {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.signup-login {
  margin-top: 1.5rem;
  font-size: 1rem;
}

.signup-login-link {
  color: var(--secondary-text-color);
  cursor: pointer;
  font-weight: bold;
}

@media (max-width: 45rem) {
  .signup-email-container {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .signup-input {
    width: 90%;
  }

  .signup-email {
    width: 90%;
  }

  .signup-send-otp {
    margin-left: 0;
  }

  .signup-verify-otp-container {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
  }
}

@media (max-width: 25rem) {
  .signup-input {
    width: auto;
  }

  .signup-email {
    width: auto;
    margin-right: 0;
  }
  .signup-enter-otp {
    font-size: 0.875rem;
    line-height: 1rem;
  }

  .signup-otp-input {
    font-size: 0.875rem;
    line-height: 1rem;
  }

  .signup-error {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}
