.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 5.125rem;
}

.input-container label {
  pointer-events: none;
  position: absolute;
  transform: translate(0.8125rem, 0.6rem) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-in;
  z-index: 1;
  color: var(--text-input-default-color);
}

.active-label {
  color: var(--text-input-active-color);
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.125rem;
}

.input-container .input-active-label {
  @extend .active-label;
  transform: translate(0, -1.375rem) scale(1);
}

.input-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.75rem;
  position: static;
  height: 2.625rem;
  left: 0rem;
  top: 0rem;
  background: #ffffff;
  border: 0.09375rem solid var(--text-input-default-border);
  box-sizing: border-box;
  border-radius: 0.5rem;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--text-input-active-color);
  width: -webkit-fill-available;
  margin-bottom: 0.5rem;
}

.input-text {
  &:disabled {
    border: 0.09375rem solid var(--text-input-disabled-border);
    color: var(--text-input-disabled-color);
  }
  &:focus {
    border: 0.09375rem solid var(--text-input-active-border);
    color: var(--text-input-actve-color);
    outline: none;
  }
}

.input-text::placeholder {
  color: #aab0cb;
  opacity: 1;
}

.input-text::-ms-input-placeholder {
  color: #aab0cb;
}

.input-error {
  color: var(--error-message-color);
  font-size: 1rem;
  margin-bottom: 2rem;
}

@media (max-width: 45rem) {
  .input-text {
    font-size: 0.875rem;
    line-height: 1rem;
  }

  .input-error {
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
}
