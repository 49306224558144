:root {
  /* Text */
  --primary-text-color: black;
  --secondary-text-color: #1976d2;
  --disabled-text-color: #a4a4a4;

  /* Buttons */
  --primary-button-background: #4d6cd9;
  --primary-button-hover-background: #9ba9e8;
  --primary-button-active-background: #1749c7;
  --primary-button-disabled-background: #e5e5e5;
  --primary-button-text-color: #fbfbfb;
  --primary-button-disabled-text-color: #a4a4a4;
  --secondary-button-background: #38f4a4;
  --secondary-button-text-color: #4d6cd9;
  --outlined-button-background: #ffffff;
  --outlined-button-text-color: #4d6cd9;
  --outlined-button-active-background: #e8eafa;
  --outlined-button-border-color: #4d6cd9;
  --textonly-button-text-color: #4d6cd9;
  --textonly-button-text-hover-color: #9ba9e8;
  --textonly-button-text-active-color: #1749c7;
  --textonly-button-text-disabled-color: #a4a4a4;
  --option-button-selected-background: #dfe5ff;
  --option-button-unselected-background: #ffffff;
  --option-button-selected-text-color: #4d6cd9;
  --option-button-unselected-text-color: #626880;
  --option-button-selected-border-color: #4d6cd9;
  --option-button-unselected-border-color: #aab0cb;

  /* Radiobutton */
  --primary-radio-button-color: #ced4f0;
  --secondary-radio-button-color: #4d6cd9;

  /* Text input*/
  --text-input-default-color: #aab0cb;
  --text-input-active-color: #626880;
  --text-input-disabled-color: #a4a4a4;
  --text-input-filled-color: #626880;
  --text-input-default-border: #aab0cb;
  --text-input-active-border: #4d6cd9;
  --text-input-disabled-border: #d6d6d6;

  --error-message-color: #ff3b2d;
}
