@import url("./themes/theme.css");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

body {
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  font-family: "Nunito", sans-serif;
  color: var(--primary-text-color);
  background: #e5e5e5;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.wrapper {
  max-width: 85.375rem;
  margin: 0 auto;
  background: #ffffff;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
}

.text-big {
  font-size: 1.125rem;
}

.form-check-input {
  font-size: 1rem;
  font-family: "Nunito", sans-serif;
  margin-bottom: 1rem;
  cursor: pointer;
}

.form-check-label {
  font-size: 1rem;
  font-family: "Nunito", sans-serif;
  margin-bottom: 1rem;
}

.form-radio {
  display: flex;
  gap: 1.5rem;
}

input:disabled {
  cursor: default;
}

.loading {
  margin: 10rem 50% 10rem 50%;
}

.form-error-message {
  font-size: 1rem;
  color: #ff0000;
  margin-top: 1rem;
}

.MuiSlider-thumb:last-child {
  margin-left: -1rem;
}

.MuiTooltip-tooltip {
  background: #ffffff !important;
  color: var(--primary-text-color) !important;
  font-size: 1rem !important;
  padding: 1rem 0.5rem !important;
  border: 1px solid var(--secondary-text-color) !important;
  border-radius: 0.5rem !important;
}

.MuiTableCell-sizeMedium {
  font-size: 1rem !important;
}

.deal-breaker {
  color: #ff0000;
  font-weight: bold;
  position: absolute;
  right: 0;
}

.form-deal-breaker {
  color: #ff0000;
  font-weight: bold;
  align-self: flex-end;
  margin-right: -0.8rem;
  font-size: 1rem;
}

.form-deal-breaker input {
  margin-right: 0.4rem;
}

.formio-component-columns {
  display: flex;
}

@media (max-width: 1023px) {
  .text-big {
    margin: 1.5rem 0;
  }

  .form-check-input {
    margin-bottom: 0.75rem;
  }

  .form-radio {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}

@media (max-width: 25rem) {
  .text-big {
    font-size: 1rem;
  }
}

@media (max-width: 650px) {
  .deal-breaker {
    margin-top: 3.5rem;
  }

  .form-deal-breaker {
    margin-right: 0.8rem;
  }
}
