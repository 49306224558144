.update-preferences-container {
  margin-top: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
}

.update-preferences-accordion {
  margin-top: 1.5rem;
}

.update-preferences-error {
  font-size: 1rem;
  color: #ff0000;
  margin-top: 1rem;
}

.update-preferences-success {
  font-size: 1rem;
  color: #0000cc;
  margin-top: 1rem;
}

.update-preferences-button-container {
  display: flex;
  margin-top: 1rem;
}

@media (max-width: 25rem) {
  .update-preferences-container {
    margin-right: 0.5rem;
  }
}
