.preferences-container {
  position: relative;
}

.preferences-logout {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  cursor: pointer;
}

.preferences-title {
  text-align: center;
  padding-top: 1rem;
  font-weight: bold;
  font-size: 1.5rem;
}

.preferences-steps-container {
  position: relative;
  display: flex;
  gap: 1.5rem;
  top: 0;
  right: 0;
  margin: 1rem auto auto auto;
  width: fit-content;
}

.preferences-step-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.preferences-step-icon {
  align-self: center;
}

.preferences-step-text {
  font-size: 1rem;
  margin-top: 0.5rem;
}

.preferences-step-active {
  color: var(--secondary-text-color);
  font-weight: bold;
}

.preferences-step-disabled {
  color: var(--disabled-text-color);
}

.preferences-content-container {
  padding-top: 2rem;
}

.preferences-content {
  margin: 0 1.5rem;
  border-top: 1px solid #d6d6d6;
}

@media (max-width: 1023px) {
  .preferences-content-container {
    padding-top: 2rem;
  }
}

@media (max-width: 25rem) {
  .preferences-step-text {
    font-size: 0.875rem;
  }
}

@media (max-width: 35rem) {
  .preferences-content {
    margin: 0 0.5rem;
  }

  .preferences-title {
    font-size: 1.25rem;
    text-align: left;
    margin-left: 1rem;
  }
}
