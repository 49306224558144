.form-question-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-question {
  font-size: 1.125rem;
  font-weight: bold;
  margin: 0;
}

.form-question-disabled {
  color: #d6d6d6;
}

.form-input-layout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  grid-gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.form-check {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.form-check-input {
  font-size: 1rem;
  font-family: "Nunito", sans-serif;
  cursor: pointer;
}

.form-check-label {
  font-size: 1rem;
  font-family: "Nunito", sans-serif;
}

.form-check-label-disabled {
  color: #d6d6d6;
}

.form-slider {
  margin-left: 1rem;
}

@keyframes blink {
  50% {
    border-color: #ff0000;
  }
}

.form-text-input-error {
  animation: blink 0.5s step-end infinite alternate;
  -webkit-animation: blink 0.5s step-end infinite alternate;
}

@media (max-width: 42rem) {
  .form-question-title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
}

@media (max-width: 25rem) {
  .form-question {
    font-size: 1rem;
  }

  .form-check-input {
    font-size: 0.875rem;
  }

  .form-check-label {
    font-size: 0.875rem;
  }
}
