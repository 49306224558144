.error-wrapper {
  margin: 0 3rem;
  padding: 12rem 0;
}

.error-wrapper-container {
  background: #ffffff;
  box-shadow: 0px 0.25rem 1rem rgba(206, 212, 240, 0.8);
  border-radius: 1rem;
}

.error-container {
  padding: 4rem;
  display: flex;
  align-items: center;
  gap: 15rem;
}

.error-title {
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 3.0625rem;
}

.error-description {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.875rem;
  color: #aab0cb;
  margin-top: 0.5rem;
}

.error-button {
  margin-top: 2rem;
}

.error-image {
  max-width: 70%;
  width: 500px; // assume this is the default size
}

@media (max-width: 70rem) {
  .error-container {
    gap: 5rem;
  }
}

@media (max-width: 60rem) {
  .error-container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }
}
